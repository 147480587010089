import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { hydrateStores, rootStore, StoreContext } from './mobx';

hydrateStores().then(() => {
  ReactDOM.render(
    <StoreContext.Provider value={rootStore}>
      <App />
    </StoreContext.Provider>,
    document.getElementById('root'),
  );
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
